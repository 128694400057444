import * as ReactSentry from "@sentry/react";
import * as Sentry from "@sentry/browser";
import React from "react";
import ReactDOM from "react-dom";
import { pdfjs } from "react-pdf";
import "./App.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

import(`./apps/${process.env.REACT_APP_ID}`).then(({ default: AppRoot, config }) => {
  const release = process.env.REACT_APP_COMMIT_HASH ? process.env.REACT_APP_COMMIT_HASH.slice(0, 8) : undefined;
  const environment = process.env.NODE_ENV !== "production" ? "local-dev" : process.env.REACT_APP_STAGE;
  const tracesSampleRate = process.env.NODE_ENV !== "production" || process.env.REACT_APP_STAGE === "dev" ? 1.0 : 0.1;
  const replaysSessionSampleRate =
    process.env.NODE_ENV !== "production" || process.env.REACT_APP_STAGE === "dev" ? 1.0 : 0.1;

  ReactSentry.init({
    dsn: config.sentry.dsn,
    beforeSend: (event) => {
      if (event.request && event.request.headers) {
        delete event.request.headers.Authorization;
      }
      return event;
    },
    integrations: [
      new Sentry.BrowserTracing(),
      new ReactSentry.Replay({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
        networkCaptureBodies: true,
        networkDetailAllowUrls: [
          process.env.REACT_APP_COORDINATOR_URL,
          process.env.REACT_APP_STUDENT_API_URL,
          process.env.REACT_APP_VOLUNTEER_API_URL,
          "https://kvn6etcwafahrlsv5yverztavi.appsync-api.us-east-1.amazonaws.com/graphql",
          "https://6qigzzpm7zhanl2scwehuk7vxi.appsync-api.us-east-1.amazonaws.com/graphql",
          "https://2c6qopbzzbbirekcixxwkqzl5e.appsync-api.us-east-1.amazonaws.com/graphql",
          "https://i2z7rassjvf4hnolxbpq3kg7ly.appsync-api.us-east-1.amazonaws.com/graphql",
        ],
        networkRequestHeaders: ["Authorization"],
        networkResponseHeaders: ["Authorization"],
      }),
    ],
    environment,
    release,
    tracesSampleRate,
    replaysSessionSampleRate,
    replaysOnErrorSampleRate: 1.0,
  });
  ReactDOM.render(<AppRoot />, document.getElementById("app"));
});
